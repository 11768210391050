angular.module('member')
    .controller('MemberEditController', ['$scope', 'Member', 'Flash', '$routeParams', 'uibDateParser', '$filter', '$location', '$uibModalInstance', 'role_id', 'Attendance', 'Venue', '$rootScope', 'Email', '$sce', 'venue', 'memberId', 'Profile', 'dateJoined',
            function ($scope, Member, Flash, $routeParams, uibDateParser, $filter, $location, $uibModalInstance, role_id, Attendance, Venue, $rootScope, Email, $sce, venue, memberId, Profile, dateJoined) {
                $scope.formName = 'memberForm';
                $scope.datepickerFormat = 'dd/MM/yyyy';
                $scope.dateFormat = 'yyyy-MM-ddTHH:mm:ssZ';
                $scope.showConfirmDuplicate = false;
                $scope.showSaveAndNew = false;
                $scope.enableDelete = false;
                $scope.deleteTooltip = '';
                $scope.saveAndNew = false;
                $scope.saveDisabled = false;
                $scope.numAttendancesPerPage = 10;
                $scope.numEmailsPerPage = 10;
                $scope.autoWelcomeTicked = false;
                $scope.showEmailTip = function () {
                    if ($uibModalInstance === null && !$routeParams.id && $scope.member.email && $scope.member.email.length > 0) {
                        return true;
                    }

                    return false;
                }

                $scope.member = {venue: venue};

                $scope.slider = {
                    options: {
                        stepsArray: [
                            {value: 'unknown'},
                            {value: '16-25'},
                            {value: '26-35'},
                            {value: '36-45'},
                            {value: '46-55'},
                            {value: '56-65'},
                            {value: '66+'}
                        ],
                        showTicks: true,
                        showTicksValues: true
                    }
                }
                if (role_id === 2) {
                    $scope.member.staff = 1
                }
                $scope.saveStatus = 'save';
                $scope.stateClass = function (elementName) {
                    if ($scope[$scope.formName][elementName].$dirty) {
                        return $scope.state(elementName) === 'valid' ? 'has-success' : 'has-error';
                    }
                    return '';
                }

                $scope.state = function (elementName) {
                    return $scope[$scope.formName][elementName].$valid ? 'valid' : 'invalid';
                }

                $scope.stateIcon = function (elementName) {
                    if ($scope[$scope.formName][elementName].$dirty) {
                        return $scope.state(elementName) === 'valid' ? 'glyphicon-ok' : 'glyphicon-remove';
                    }
                    return '';
                }

                $scope.loadAttendance = function () {
                    $scope.attendances = Attendance.query({member_id: $scope.member.id},
                        function (success) {

                        })
                }

                $scope.loadEmails = function () {
                    $scope.emails = Email.query({member_id: $scope.member.id});
                }

                $scope.canSendWelcomeEmail = function () {
                    return $scope.member.email && $scope.memberForm.email.$valid ? true : false;
                }

                $scope.welcome = function () {
                    if (!$scope.member.id && $scope.canSendWelcomeEmail() && $scope.autoWelcomeTicked === false) {
                        $scope.member.welcome = 1;
                        $scope.autoWelcomeTicked = true;
                    }
                }

                $scope.delete = function () {
                    if (confirm('Are you sure you want to delete ' + $scope.member.name + '?')) {
                        Member.delete({id: $scope.member.id},
                            function (success) {
                                success.deleted = true;
                                Flash.create('success', $scope.member.name + ' deleted successfully', 5000, {container: 'flash-master'});
                                if ($uibModalInstance !== null) {
                                    $uibModalInstance.close(success);
                                } else {
                                    $location.path('/members');
                                }
                            },
                            function (error) {
                                $scope.createFlash('danger', 'Delete failed');
                            });
                    }
                }

                $scope.restore = function () {
                    if (confirm('Are you sure you want to restore ' + $scope.member.name + '?')) {
                        Member.restore({id: $scope.member.id},
                            function (success) {
                                success.deleted = false;
                                $scope.saveDisabled = false;
                                $scope.member = success;
                                Flash.create('success', $scope.member.name + ' restored successfully', 5000, {container: 'flash-master'});
                                if ($uibModalInstance !== null) {
                                    $uibModalInstance.close(success);
                                }
                            },
                            function (error) {
                                $scope.createFlash('danger', 'Restore failed');
                            });
                    }
                }

                $scope.load = function () {
                    $scope.venues = Venue.query();
                    $scope.sources = Member.sources();
                    $scope.user = Profile.get();
                    if ((null === $uibModalInstance && $routeParams.id) || memberId) {
                        $scope.member = Member.get({id: memberId ? memberId : $routeParams.id},
                            function (success) {
                                $scope.member.date_joined = uibDateParser.parse($scope.member.date_joined, $scope.dateFormat);
                                if ($scope.user.role !== 'admin') {
                                    $scope.deleteTooltip = $sce.trustAsHtml('You can only delete a member if you are an admin user');
                                }
                                if ($scope.user.role === 'admin') {
                                    $scope.enableDelete = true;
                                }
                                if ($scope.member.deleted_at){
                                    $scope.saveDisabled = true;
                                }
                                $rootScope.$emit('breadcrumbUpdate', {href: '', name: $scope.member.name});
                            }, function (response) {
                                $scope.createFlash('danger', response.statusText, 5000);
                            }
                        );

                    } else {
                        if ($uibModalInstance === null) {
                            $scope.showSaveAndNew = true;
                        }
                        $scope.member.date_joined = dateJoined;
                    }
                }

                $scope.emailMessage = function (email) {
                    return $sce.trustAsHtml(email.message);
                }

                $scope.save = function () {
                    $scope.saveStatus = 'saving';
                    if ($scope.member.postcode) {
                        $scope.member.postcode = $scope.member.postcode.toUpperCase();
                    }
                    if ($scope.member.membership_number) {
                        $scope.member.membership_number = $scope.member.membership_number.toUpperCase();
                    }
                    if (isNaN($scope.member.id)) {
                        Member.save($scope.member,
                            function (success) {
                                $scope.success(success);
                            },
                            function (failure) {
                                $scope.failure(failure);
                            });
                    } else {
                        Member.update({id: $scope.member.id}, $scope.member,
                            function (success) {
                                $scope.success(success);
                            },
                            function (failure) {
                                $scope.failure(failure);
                            });
                    }
                    $scope.saveStatus = 'save';
                }

                $scope.success = function (success) {
                    var saveMessage = success.name + ' saved successfully';
                    $scope.showConfirmDuplicate = false;
                    $scope.member.id = success.id;
                    $scope.member.welcome = 0;
                    $scope.saveDisabled = success.deleted_at ? true : false;

                    if ($uibModalInstance !== null) {
                        $uibModalInstance.close(success);
                    } else {
                        if ($scope.saveAndNew === true) {
                            saveMessage += ', adding another new member';
                            $scope.createFlash('success', saveMessage, 3000);
                            $scope.member = {venue: venue};
                            $scope[$scope.formName].$setPristine();
                            $route.reload();
                        } else {
                            $scope.createFlash('success', saveMessage, 3000);
                            $location.url('/members/edit/' + success.id);
                        }
                    }
                }

                $scope.failure = function (failure) {
                    if (failure.status === 409) {
                        var existingUser = failure.data.duplicate;
                        var message = 'A user with this name already exists, please confirm to add another';
                        message += ' or <a href="/members/edit/' + existingUser.id + '">edit existing user</a>, the existing user is:<ul>';
                        message += '<li>Name: ' + existingUser.first_name + ' ' + existingUser.last_name + '</li>';
                        message += '<li>email: ' + existingUser.email + '</li>';
                        message += '<li>Date Joined: ' + $filter('date')(existingUser.date_joined, $scope.datepickerFormat) + '</li>';
                        message += '<li>Postcode: ' + existingUser.postcode + '</li>';
                        message += '<li>Membership Number: ' + existingUser.membership_number + '</li>';
                        message += '</ul>';

                        $scope.createFlash('danger', message, 10000);
                        $scope.showConfirmDuplicate = true;
                    } else if (failure.status === 422) {
                        var message = 'Validation failed: <ul>';
                        Object.keys(failure.data).forEach(function (key, index) {
                            for (var i = 0; i < failure.data[key].length; i++) {
                                message += '<li>' + failure.data[key][i] + '</li>';
                            }
                        });
                        message += '</ul>';

                        $scope.createFlash('danger', message);
                    }
                }

                $scope.cancel = function () {
                    if ($uibModalInstance === null) {
                        window.history.back();
                    } else {
                        $uibModalInstance.dismiss('cancel');
                    }
                }

                $scope.createFlash = function (type, message, duration) {
                    if (typeof duration === 'undefined') {
                        var duration = 5000;
                    }
                    if ($uibModalInstance === null) {
                        Flash.create(type, message, duration, {container: 'flash-master'});
                    } else {
                        Flash.create(type, message, duration, {container: 'flash-modal'});
                    }
                }
            }
        ]
    );
