angular.module('email')
.controller('EmailViewController', ['$scope', 'Email', 'Flash', '$location', '$routeParams', '$sce',
    function ($scope, Email, Flash, $location, $routeParams, $sce) {

        $scope.load = function () {
            if ($routeParams.id) {
                $scope.email = Email.get({id:$routeParams.id},
                    function (success) {
                    }, function (response) {
                        Flash.create('danger', response.statusText, 5000, {container:'flash-master'})
                    }
                );

            } 
        }
        
        $scope.emailMessage = function (email) {
            return $sce.trustAsHtml(email.message);
        }
    }
])