angular.module('report')
.controller('ReportListController', ['$scope', 'Report', 'moment',
    function($scope, Report, moment){
        $scope.numPerPage = 10;
        $scope.showMembers = false;
        $scope.showEvents = false;
        $scope.orderByField = 'name';
        $scope.reverseSort = false;
        $scope.membersByAgeData = [];
        $scope.membersByGenderData = [];
        $scope.signupsOverTime = {periods:[], series:[]};
        $scope.ageRanges = [];
        Report.query(
            {report:'byage'},
            function (success){
                var data = angular.fromJson(angular.toJson(success));
                for (var i = 0; i < data.length; i++) {
                    $scope.membersByAgeData.push(data[i].totalCount);
                    $scope.ageRanges.push(data[i].age_range);
                }
        });

        Report.query(
            {report:'countsbygender'},
            function (success){
                var data = angular.fromJson(angular.toJson(success));
                for (var i = 0; i < data.length; i++) {
                    $scope.membersByGenderData.push({name:data[i].gender, y:data[i].totalCount}); 
                }    
            }
        );
        Report.query(
            {report:'signups'},
            function (success){
                var data = angular.fromJson(angular.toJson(success));
                
                var period;
                for (var i = 0; i < data.length; i++) {
                    period = new Date(data[i].date_joined*1000);
                    $scope.signupsOverTime.periods.push(moment(period).format('MMM YYYY')); 
                    $scope.signupsOverTime.series.push(data[i].totalCount);
                }    
            }
        );

        $scope.toggleMembers = function () {
            $scope.showMembers = $scope.showMembers === true ? false : true;
        }

        $scope.toggleEvents = function () {
            $scope.showEvents = $scope.showEvents === true ? false : true;
        }

        $scope.membersByAgeConfig = {
            chart: {
                type: 'column',
            },
            series: [{
                name: 'Members by age',
                data: $scope.membersByAgeData,
                id: 'Members by age',
                title: 'Members by age'
            }],
            xAxis: {
                categories: $scope.ageRanges,
                crosshair: true,
            },
            yAxis: {
                title:'Number'
            },
            title: {
                text: 'Members by age'
            },
            credits: {
                enabled: false
            },
        }
        $scope.membersByGenderConfig = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: 'Members By Gender'
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                        }
                    }
                }
            },
            series: [{
                name: 'Members by gender',
                data: $scope.membersByGenderData,
            }],

            title: {
                text: 'Members by gender'
            },
            credits: {
                enabled: false
            },
        }
        $scope.signUpsOverTimeConfig =    
        {
            chart: {
                type: 'column',
            },
            series: [{
                name: 'by month',
                data: $scope.signupsOverTime.series,
                title: 'New Memberships'
            }],
            xAxis: {
                categories:$scope.signupsOverTime.periods,
            },
            yAxis: {
                title:'Number'
            },
            title: {
                text: 'New Memberships last 12 months'
            },
            credits: {
                enabled: false
            },
        }
    }
    ]
);
