angular.module('email')
.controller('TemplateListController', ['$scope', 'Template', '$sce',
    function($scope, Template, $sce){
        $scope.templates = Template.query();
        
        $scope.templateHtml = function (template) {
            return $sce.trustAsHtml(template.message)
        }
    }
    ]
);
