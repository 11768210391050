angular.module('venue')
.controller('VenueEditController', ['$scope', 'Venue', 'Flash', '$location', '$routeParams', '$uibModalInstance', '$rootScope',
    function ($scope, Venue, Flash, $location, $routeParams, $uibModalInstance, $rootScope) {
        $scope.formName = 'venueForm';
        $scope.saveStatus = 'save';
        $scope.showConfirmDuplicate = false;
        $scope.venue = {};
        $scope.stateClass = function (elementName){
            if ($scope[$scope.formName][elementName].$dirty){
                return $scope.state(elementName) === 'valid' ? 'has-success' : 'has-error';
            }
            return '';
        }

        $scope.state = function (elementName){
            return $scope[$scope.formName][elementName].$valid ? 'valid' : 'invalid';
        }

        $scope.stateIcon = function(elementName) {
            if ($scope[$scope.formName][elementName].$dirty){
                return $scope.state(elementName) === 'valid' ? 'glyphicon-ok' : 'glyphicon-remove';
            }
            return '';
        }

        $scope.load = function () {
            if ($uibModalInstance === null && $routeParams.id) {
                $scope.venue = Venue.get({id:$routeParams.id},
                    function (success) {
                        $rootScope.$emit('breadcrumbUpdate', {href:'', name:success.name});
                    }, function (response) {
                        Flash.create('danger', response.statusText, 5000, {container:'flash-master'})
                    }
                );
            } 
        }

        $scope.save = function() {
            $scope.saveStatus = 'saving';
            if ($scope.venue.postcode ){
                $scope.venue.postcode = $scope.venue.postcode.toUpperCase();
            }
            if (isNaN($scope.venue.id)){
                Venue.save($scope.venue,
                    function(success) {
                        $scope.success(success);
                        $scope.venue.id = success.id;
                    },
                    function (failure){
                        $scope.failure(failure);  
                });
            } else {
                Venue.update({id:$scope.venue.id}, $scope.venue,
                    function(success) {
                        $scope.success(success);
                    },
                    function (failure){
                        $scope.failure(failure);  
                });
            }
        }

        $scope.success = function (success) {
            Flash.create('success', 'Venue saved successfully', 2000, {container:'flash-master'});
            $scope.saveStatus = 'save';
 
            if ($uibModalInstance !== null) {
                $uibModalInstance.close(success);
            } else {
                $location.url('/venues/edit/'+success.id);
            }
        }

        $scope.failure = function (failure){
            $scope.saveStatus = 'save';
            if (failure.status === 409){
                var existingVenue = failure.data.duplicate;
                var message = 'A venue with this name already exists, please confirm to add another, the existing venue is:<ul>';
                message += '<li>Name: '+existingVenue.name + '</li>';
                message += '<li>Postcode: '+existingVenue.postcode+'</li>';
                message += '</ul>';

                Flash.create('danger', message, 10000, {container:'flash-master'});
                $scope.showConfirmDuplicate = true;
            } else if (failure.status === 422) {
                var message = 'Validation failed: <ul>';
                Object.keys(failure.data).forEach(function(key,index) {
                    for (var i = 0; i < failure.data[key].length; i++) {
                        message += '<li>' + failure.data[key][i] + '</li>';
                    }
                });
                message += '</ul>';

                Flash.create('danger', message, 5000, {container:'flash-master'});
            }
        }

        $scope.cancel = function () {
            if ($uibModalInstance === null) {
                window.history.back();
            } else {
                $uibModalInstance.dismiss('cancel');
            }
        }
    }
])