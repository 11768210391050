angular.module('event')

.factory('Event', ['$resource', '$cacheFactory',
    function ($resource, $cacheFactory) {
        var eventCache = $cacheFactory('events');
        return $resource('api/events/:id',
            [],
            {
                query: { method:'GET', isArray:true, cache:eventCache},
                update: { method:'PUT'},
                types: { method:'GET', url:'api/eventtypes/', isArray:true},
                addCost: {method:'POST', url:'/api/events/:eventid/costs'},
                deleteCost: {method:'DELETE', url:'/api/events/:eventid/costs/:costid'}
            }
        )
    }
])

.factory('Attendance', ['$resource',
    function ($resource) {
        return $resource('api/attendances/:id',
            [],
            {
                query: { method:'GET', isArray:true},
                update: { method:'PUT'},
            }
        )
    }
])