angular.module('price')
.controller('PriceListController', ['$scope', 'Price', 'Flash',
    function($scope, Price, Flash){
        $scope.numPerPage = 10;
        $scope.orderByField = 'amount';
        $scope.reverseSort = true;
        $scope.prices = Price.query();

        $scope.cancel = function () {
            window.history.back();
        }

        $scope.save = function () {
            //if ($scope.pricesForm.$dirty) {
                angular.forEach($scope.prices, function (price) {
                        Price.update({id:price.id}, price,
                        function (success) {
                            Flash.create('success', price.name + ' price saved successfully', 5000, {container:'flash-master'});
                        }, function (failure){
                                Flash.create('danger', 'An error occurred', 5000, {container:'flash-master'});
                        })
                    }
                )}
            //}
    }
    ]
);
