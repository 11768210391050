'use strict';
angular.module('modernjive', [
    'ngRoute',
    'ngMessages',
    'ui.bootstrap',
    'angularMoment',
    'ui.tinymce',
    'ngResource',
    'ngIdle',
    'ngFlash',
    'ngAnimate',
    'member',
    'venue',
    'email',
    'event',
    'user',
    'price',
    'report',
    'angular-loading-bar',
    'angularUtils.directives.dirPagination',
    'rzModule'
])

    .controller('DashboardController', ['$scope', 'Event', 'Member', 'Activity', '$sce',
            function ($scope, Event, Member, Activity, $sce) {
                $scope.eventLimit = 10;
                $scope.activityLimit = 10;
                $scope.memberLimit = 10;
                $scope.events = Event.query({limit: $scope.eventLimit});
                $scope.members = Member.query({latest: true, limit: $scope.memberLimit});
                $scope.activities = Activity.query({limit: $scope.activityLimit});

                $scope.increaseActivityLimit = function () {
                    $scope.activityLimit += 10;
                    $scope.activities = Activity.query({limit: $scope.activityLimit});
                }

                $scope.increaseMemberLimit = function () {
                    $scope.memberLimit += 10;
                    $scope.members = Member.query({latest: true, limit: $scope.memberLimit});
                }

                $scope.increaseEventLimit = function () {
                    $scope.eventLimit += 10;
                    $scope.events = Event.query({limit: $scope.eventLimit});
                }

                $scope.activityInfo = function (activity) {
                    return $sce.trustAsHtml(activity.user.name + ' ' + activity.description);
                }
            }
        ]
    )

    .controller('BreadcrumbController', ['$scope', '$rootScope', '$timeout',
            function ($scope, $rootScope, $timeout) {
                $scope.breadcrumbs = [];
                var baseBreadCrumb = {
                    href: '/',
                    name: 'Home'
                };
                $scope.$on("$routeChangeSuccess", function (event, current, previous) {
                    $scope.breadcrumbs = [];
                    $scope.breadcrumbs.push(baseBreadCrumb);
                    if (current.$$route.breadcrumb) {
                        if (!angular.isArray(current.$$route.breadcrumb)) {
                            current.$$route.breadcrumb = [current.$$route.breadcrumb];
                        }

                        for (var i = 0; i < current.$$route.breadcrumb.length; i++) {
                            if ($scope.breadcrumbs.indexOf(current.$$route.breadcrumb[i]) < 0) {
                                $scope.breadcrumbs.push(current.$$route.breadcrumb[i]);
                            }
                        }
                    }
                })

                $rootScope.$on('breadcrumbUpdate', function (event, data) {
                    // timeout to ensure route change happens before later breadcrumbs are added
                    $timeout(function () {
                        $scope.breadcrumbs.push(data);
                    }, 200)
                })
            }
        ]
    )

    .controller('NavController', ['$scope', 'Idle', '$uibModal',
            function ($scope, Idle, $uibModal) {
                $scope.$on("$routeChangeSuccess", function (event, current, previous) {
                    $scope.activeMenu = current.$$route.activeMenu;
                })

                $scope.$on('IdleTimeout', function () {
                    window.location.href = '/login';
                });

                $scope.editProfile = function () {
                    var modalInstance = $uibModal.open({
                        templateUrl: '/js/angular/profile.html',
                        controller: 'ProfileController',
                    });

                    modalInstance.rendered.then(function () {
                        $('.modal-content').css('max-height', $(window).height() * 0.9);
                    });
                }
            }
        ]
    )

    .controller('HelpController', ['$scope', '$rootScope',
            function ($scope, $rootScope) {
                $rootScope.$emit('breadcrumbUpdate', {href:'', name:'Help'});
            }
        ]
    )

    .controller('ProfileController', ['$scope', '$uibModalInstance', 'Profile', 'Flash',
        function ($scope, $uibModalInstance, Profile, Flash) {
            $scope.user = Profile.query();
            $scope.saveStatus = 'save';
            $scope.formName = 'userForm';

            $scope.cancel = function () {
                $uibModalInstance.dismiss();
            }

            $scope.save = function () {
                if ($scope.user.password && $scope.user.password.length > 0 && $scope.user.password !== $scope.user.password_confirmation) {

                    Flash.create('danger', 'Passwords don\'t match, please check', 5000, {container: 'flash-modal'});
                    return;
                }
                Profile.update($scope.user,
                    function (success) {
                        $uibModalInstance.close(success);
                        Flash.create('success', 'Profile saved successfully', 5000, {container: 'flash-master'});
                    },
                    function (failure) {
                        if (failure.status === 422) {
                            var message = 'Validation failed: <ul>';
                            Object.keys(failure.data).forEach(function (key, index) {
                                for (var i = 0; i < failure.data[key].length; i++) {
                                    message += '<li>' + failure.data[key][i] + '</li>';
                                }
                            });
                            message += '</ul>';
                            Flash.create('danger', message, 5000, {container: 'flash-modal'});
                        }
                    });
            }
            $scope.stateClass = function (elementName) {
                if ($scope[$scope.formName][elementName].$dirty) {
                    return $scope.state(elementName) === 'valid' ? 'has-success' : 'has-error';
                }
                return '';
            }

            $scope.state = function (elementName) {
                return $scope[$scope.formName][elementName].$valid ? 'valid' : 'invalid';
            }

            $scope.stateIcon = function (elementName) {
                if ($scope[$scope.formName][elementName].$dirty) {
                    return $scope.state(elementName) === 'valid' ? 'glyphicon-ok' : 'glyphicon-remove';
                }
                return '';
            }
        }
    ])

    .config(['$qProvider', function ($qProvider) {
        $qProvider.errorOnUnhandledRejections(false);
    }])

    .config(['IdleProvider', 'KeepaliveProvider',
        function (IdleProvider, KeepaliveProvider) {
            // configure Idle settings
            IdleProvider.idle(600); // in seconds
            IdleProvider.timeout(3600); // in seconds
            KeepaliveProvider.interval(300); // in seconds
            KeepaliveProvider.http({
                method: 'GET',
                url: '/api/idle',
                ignoreLoadingBar: true
            });
        }])

    .config(['uibDatepickerConfig', 'uibDatepickerPopupConfig',
        function (datepickerConfig, datepickerPopupConfig) {
            datepickerConfig.startingDay = 1;
            datepickerConfig.showWeeks = false;

            datepickerPopupConfig.datepickerPopup = "dd-MM-yyyy";
        }
    ])

    .run(['Idle', function (Idle) {
        // start watching when the app runs. also starts the Keepalive service by default.
        Idle.watch();
    }])

    .run(['$cacheFactory', '$rootScope', function ($cacheFactory, $rootScope) {
        var host = window.location.host;
        var socket = io.connect('https://' + host + ':3000');

        socket.on('memberupdates:App\\Events\\MemberUpdate', function (message) {
            $rootScope.$broadcast('member:updated', message.data);
            if (message.data && message.data.member) {
                if (!$cacheFactory.get('members')) {
                    return;
                }
                var cacheKey = 'api/members';
                var memberCache = $cacheFactory.get('members').get(cacheKey);
                if (memberCache) {
                    var members = angular.fromJson(memberCache[1]);
                    var memberFound = false;
                    angular.forEach(members, function (member, key) {
                        if (member.id === message.data.member.id) {
                            memberFound = true;
                            members[key] = message.data.member;
                        }
                    });
                    if (memberFound === false) {
                        members.push(message.data.member);
                    }
                    memberCache[1] = angular.toJson(members);
                    $cacheFactory.get('members').put(cacheKey, memberCache);
                }
            }
        });

        socket.on('memberupdates:App\\Events\\MemberDelete', function (message) {
            if (message.data && message.data.memberId) {
                $rootScope.$broadcast('member:deleted', message.data);
                if (!$cacheFactory.get('members')) {
                    return;
                }
                var cacheKey = 'api/members';
                var memberCache = $cacheFactory.get('members').get(cacheKey);
                if (memberCache) {
                    var members = angular.fromJson(memberCache[1]);
                    angular.forEach(members, function (member, key) {
                        if (member.id === message.data.memberId) {
                            members[key].deleted_at = moment();
                        }
                    });

                    memberCache[1] = angular.toJson(members);
                    $cacheFactory.get('members').put(cacheKey, memberCache);
                }
            }
        });

        socket.on('eventupdates:App\\Events\\EventUpdate', function (message) {
            if (message.data && message.data.event) {
                if (!$cacheFactory.get('events')) {
                    return;
                }
                var cacheKey = 'api/events';
                var eventCache = $cacheFactory.get('events').get(cacheKey);
                if (eventCache) {
                    var events = angular.fromJson(eventCache[1]);
                    var eventFound = false;
                    angular.forEach(events, function (event, key) {
                        if (event.id === message.data.event.id) {
                            eventFound = true;
                            events[key] = message.data.event;
                        }
                    });
                    if (eventFound === false) {
                        events.push(message.data.event);
                    }
                    eventCache[1] = angular.toJson(events);
                    $cacheFactory.get('events').put(cacheKey, eventCache);
                }
            }
        });
    }])

    .config(["$routeProvider", "$locationProvider", function ($routeProvider, $locationProvider) {
        $routeProvider
            .when('/', {
                templateUrl: '/js/angular/index.html',
                controller: 'DashboardController',
                activeMenu: '/',
                reloadOnSearch: false, // stop reload when switching tabs and updating location.path
            })
            .when('/profile', {
                templateUrl: '/js/angular/profile.html',
                controller: 'ProfileController',
                activeMenu: '/',
                reloadOnSearch: false, // stop reload when switching tabs and updating location.path
            })
            .when('/help', {
                templateUrl: '/js/angular/help.html',
                controller: 'HelpController',
                activeMenu: '/',
                reloadOnSearch: false, // stop reload when switching tabs and updating location.path
            })
        $locationProvider.html5Mode(true);
    }])

    .config(['FlashProvider',
        function (FlashProvider) {
            FlashProvider.setTimeout(3000);
            FlashProvider.setShowClose(true);
        }])

    .directive('mjLoading', ['$http', function ($http) {
        return {
            restrict: 'A',
            link: function (scope, elm, attrs) {
                scope.$root.$on('cfpLoadingBar:started', function (event) {
                    $('.container-fluid').css('opacity', 0.3);
                    //elm.show()
                })

                scope.$root.$on('cfpLoadingBar:completed', function (event) {
                    $('.container-fluid').css('opacity', 1);
                    //elm.hide();
                })
            }
        };
    }])

    .factory('Activity', ['$resource',
        function ($resource) {
            return $resource('api/activities/:id',
                [],
                {
                    query: {method: 'GET', isArray: true, ignoreLoadingBar: true},
                }
            )
        }
    ])

    .factory('Profile', ['$resource',
        function ($resource) {
            return $resource('api/profile',
                [],
                {
                    query: {method: 'GET'},
                    update: {method: 'PUT'}
                }
            )
        }
    ])