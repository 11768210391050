angular.module('report')
.controller('ReportMemberController', ['$scope', 'Report', 'Flash', '$location', '$routeParams', '$uibModal', '$rootScope', 'Template',
    function ($scope, Report, Flash, $location, $routeParams, $uibModal, $rootScope, Template) {
        $scope.periodNumber = 3;
        $scope.emailNumber = null;
        $scope.orderByField = 'name';
        $scope.reverseSort = false;
        $scope.periods = ['days', 'weeks', 'months', 'years'];
        $scope.selectedPeriod = 'months';
        $scope.selectedEmailPeriod = null;
        $scope.data = [];
        $scope.gender = null;
        $scope.emailTemplate = {};
        $scope.enableEmail = false;
        $scope.allCheckboxesChecked = true;
        $scope.title = '';
        $scope.breadCrumb;
        $scope.reportType = 'lapsed';
        if ($routeParams.type) {
            switch ($routeParams.type) {
                case 'new':
                    $scope.reportType = 'new';
                    $scope.title = 'New members in the last';
                    $scope.breadCrumb = {href:'', name:'New members'};
                    $scope.orderByField = 'date_joined';
                    $scope.reverseSort = true;
                    break;
                case 'lapsed':
                    $scope.reportType = 'lapsed';
                    $scope.title = 'Find members who haven\'t attended for';
                    $scope.orderByField = 'last_attended';
                    $scope.reverseSort = true;
                    $scope.breadCrumb = {href:'', name:'Lapsed Members'};
                    $scope.emailTemplate = Template.get({id:2});
                    break;

                case 'active':
                    $scope.orderByField = 'num_events_attended';
                    $scope.reverseSort = true;
                    if ($routeParams.most){
                        $scope.reportType = 'mostactive';
                        $scope.title = 'Find most active members during the last';
                        $scope.breadCrumb = {href:'', name:'Most Active Members'};
                    } else if ($routeParams.least){
                        $scope.reportType = 'leastactive';
                        $scope.title = 'Find least active members during the last';
                        $scope.breadCrumb = {href:'', name:'Least Active Members'};
                    } 

                    break;
                case 'gender':
                    $scope.reportType = 'gender';
                    if ($routeParams.male){
                        $scope.title = 'Find male members who joined during the last';
                        $scope.breadCrumb = {href:'', name:'Male Members'};
                        $scope.gender = 'male';
                    } else if ($routeParams.female){
                        $scope.title = 'Find female members who joined during the last';
                        $scope.breadCrumb = {href:'', name:'Female Members'};
                        $scope.gender = 'female';
                    } 
                    break;
                default:
            }
            if (angular.isObject($scope.breadCrumb)) {
                $rootScope.$emit('breadcrumbUpdate', $scope.breadCrumb);
            }
        }

        $scope.generate = function () {
            Report.query(
                {
                    report:$scope.reportType, 
                    period:$scope.selectedPeriod, 
                    quantity:$scope.periodNumber,
                    lastEmailedQuantity:$scope.emailNumber,
                    lastEmailedPeriod:$scope.selectedEmailPeriod,
                    gender:$scope.gender,
                    crew:$scope.crew
                },
                function (success){
                    var data = angular.fromJson(angular.toJson(success))
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].subscribed === 1){
                            data[i].selected = true;
                        } else {
                            data[i].selected = null;
                        }
                    }
                    $scope.data = data;
                    $scope.enableEmail = $scope.data.length > 0;
                }, function (failure){
                    $scope.generated = true;
                    Flash.create('danger', 'An error occurred', 5000, {container:'flash-master'})
            })
        }

        $scope.checkAllToggle = function () {
            for (var i = 0; i < $scope.data.length; i++) {
                $scope.data[i].selected = $scope.allCheckboxesChecked;
            }
        }

        $scope.email = function () {
            if ($scope.enableEmail === false){
                return;
            }

            var modalInstance = $uibModal.open({
                templateUrl: '/js/angular/members/email.html',
                controller: 'MemberEmailController',
                size: 'lg',
                resolve : 
                {
                    recipients: function (){
                        var emails = [];
                        for (var i = 0; i < $scope.data.length; i++) {
                            if ($scope.data[i].selected === true){
                                emails.push({email:$scope.data[i].email, id:$scope.data[i].id})
                            }
                        }

                        return emails;
                    },
                    template: function (){
                        return $scope.emailTemplate;
                    }
                }
            });

            modalInstance.rendered.then(function(){
                $('.modal-content').css('max-height', $(window).height() * 0.9);
                $('.modal-content').css('min-height', 710);
            });
        }
    }
])