angular.module('member')
.controller('MemberListController', ['$scope', 'Member', 'Profile', '$filter', 'Flash',
    function($scope, Member, Profile, $filter, Flash){
        $scope.user = Profile.get();
        $scope.members = Member.query();
        $scope.allCheckboxesChecked = false;
        $scope.numPerPage = 10;
        $scope.includeDeleted = false;
        $scope.orderByField = 'date_joined';
        $scope.reverseSort = true;
        $scope.checkAllToggle = function () {
            $('input:checkbox').prop('checked', $scope.allCheckboxesChecked);
        }

        $scope.membersSelected = function () {
            return $filter('filter')($scope.members, {selected:true});
        }

        $scope.$watch('includeDeleted', function(newVal, oldVal) {
            if (newVal !== oldVal){
                if (newVal){
                    $scope.members = Member.query({includedeleted:1});
                } else {
                    $scope.members = Member.query();
                }
            }
        });

        $scope.merge = function (){
            if ($scope.membersSelected().length === 0){
                return false;
            }
            Member.merge({members: $scope.membersSelected()}, function (success) {
                Flash.create('success', ' merge successful', 5000, {container:'flash-master'});
            }, function (failure){
                Flash.create('danger', 'An error occurred', 5000, {container:'flash-master'});
            })
        }

        $scope.$on('member:deleted', function(event,data) {
            angular.forEach($scope.members, function(member, key){
                if (member.id === data.memberId){
                    $scope.members.splice(key, 1);
                    return;
                }
            })
        });
        $scope.$on('member:updated', function(event,data) {
            angular.forEach($scope.members, function(member, key){
                if (member.id === data.member.id){
                    $scope.members[key] = data.member;
                    $scope.$apply();
                    return;
                }
            })
        });
    }
    ]
);
