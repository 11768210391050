angular.module('venue')
.controller('VenueListController', ['$scope', 'Venue',
    function($scope, Venue){
        $scope.numPerPage = 10;
        $scope.orderByField = 'name';
        $scope.reverseSort = false;
        $scope.venues = Venue.query();
    }
    ]
);
