angular.module('event')
.controller('EventEditController', ['$scope', 'Event', 'Flash', '$routeParams', 'uibDateParser', '$filter', '$location', 'Venue', 'Member', 'Attendance', '$uibModal', '$timeout', '$rootScope', 'Price',
    function($scope, Event, Flash, $routeParams, uibDateParser, $filter, $location, Venue, Member, Attendance, $uibModal, $timeout, $rootScope, Price){
        $scope.formName = 'eventForm';
        $scope.datepickerFormat = 'dd/MM/yyyy';
        $scope.dateFormat = 'yyyy-MM-ddTHH:mm:ssZ';
        $scope.activeTab = $location.hash() === '' ? 'details' : $location.hash();
        $scope.event = {date:new Date, attendances:[]};
        $scope.saveStatus = 'save';
        $scope.loaded = false;
        $scope.cost = {};
        $scope.showTips = true;
        $scope.stateClass = function (elementName, formName){
            if (!formName) {
                var formName = $scope.formName;
            }
            if ($scope[formName][elementName].$dirty){
                return $scope.state(elementName, formName) === 'valid' ? 'has-success' : 'has-error';
            }
            return '';
        }

        $scope.state = function (elementName, formName){
            return $scope[formName][elementName].$valid ? 'valid' : 'invalid';
        }

        $scope.stateIcon = function(elementName, formName) {
            if (!formName) {
                var formName = $scope.formName;
            }
            if ($scope[formName][elementName].$dirty){
                return $scope.state(elementName, formName) === 'valid' ? 'glyphicon-ok' : 'glyphicon-remove';
            }
            return '';
        }

        $scope.addStaff = function () {
            if (!$scope.staff){
                alert('Please choose a staff member');
                return;
            }
            $scope.saveAttendee($scope.staff, 2, 0);
        }

        $scope.alreadyInList = function (member) {
            var attendances = $scope.event.attendances;
            for (var i = 0; i < attendances.length; i++) {
                if (attendances[i].member.id === member.id) {
                    return attendances[i];
                }
            }    
            return false;
        }

        $scope.addCost = function () {
            var cost = $scope.cost;
            Event.addCost({eventid:$scope.event.id}, $scope.cost,
            function (success){
                var newCost = angular.fromJson(angular.toJson(success));
                $scope.event.costs.push(newCost);
                $scope.cost = {};
                $timeout(function (){$('#costdescription').focus();}, 100);
            }, function (failure){
                    Flash.create('danger', 'Adding cost failed', 5000, {container:'flash-master'});
                })
        }

        $scope.deleteCost = function (cost) {
            Event.deleteCost({eventid:$scope.event.id, costid:cost.id},
            function (success){
                $scope.event.costs.splice($scope.event.costs.indexOf(cost),1);
            }, function (failure){
                    Flash.create('danger', 'Deleting cost failed', 5000, {container:'flash-master'});
                })
        }

        $scope.editMember = function (member) {
            var modalInstance = $uibModal.open({
                backdrop: 'static',
                templateUrl: '/js/angular/members/editModal.html',
                controller: 'MemberEditController',
                size: 'lg',
                resolve:
                    {
                        role_id: function () {return null},
                        venue: function () {return $scope.event.venue},
                        memberId: member.id,
                        dateJoined: function(){return null}
                    }
            });

            modalInstance.rendered.then(function(){
                $('.modal-content').css('max-height', 800);
            });

            modalInstance.result.then(function (member) {
                if (member.deleted === true) {
                    angular.forEach($scope.event.attendances, function (attendance, key) {
                        if (attendance.member.id === member.id) {
                            $scope.event.attendances.splice(key, 1);
                        }
                    });
                } else {
                    angular.forEach($scope.event.attendances, function (attendance) {
                        if (attendance.member.id === member.id) {
                            attendance.member = member;
                        }
                    });
                }
            });
        }

        $scope.saveAttendee = function (member, role_id, fee) {
            var duplicate = $scope.alreadyInList(member)
            if (duplicate !== false) {
                var list = duplicate.role_id === 1 ? 'customer' : 'staff';
                if (!confirm(member.name + " is already on the " + list +" list for this event, are you sure you want to add again?")) {
                    return;
                }
            }
            Attendance.save(
                {
                    member_id:member.id, 
                    event_id:$scope.event.id,
                    fee:fee,
                    role_id:role_id
                },
                function (success){
                    $scope.event.attendances.push(angular.fromJson(angular.toJson(success)));

                    if (role_id === 1){
                        $scope.member = null;
                        $timeout(function (){$('#member').focus();}, 100);
                    } else {
                        $scope.staff = null;
                        $timeout(function (){$('#staff').focus();}, 100);
                    }
                },
                function (failure){
                    alert('failure')
            }); 
        }

        $scope.addCrewButtonFocus = function () {
            $timeout(function (){$('#addCrewButton').focus();}, 50);
        }

        $scope.addAttendee = function () {
            if (!$scope.member){
                alert('Please choose a member');
                return;
            }
            if (isNaN($scope.fee)) {
                alert('Please specify a fee, numbers only (zero for no payment)');
                return;
            } else {
                $scope.fee = $filter('number')($scope.fee, 2);
            }
            $scope.saveAttendee($scope.member, 1, $scope.fee);
        }

        $scope.calcFee = function () {
            if ($scope.member.concession === 1) {
                $scope.fee = $scope.concessionPrice;
            } else {
                $scope.fee = $scope.normalPrice;
            }
            $timeout(function (){$('#fee').select();}, 100);
        }

        $scope.deleteAttendance = function (attendance) {
            if (confirm("Are you sure you want to remove "+attendance.member.first_name + ' ' + attendance.member.last_name+" from this event?")){
                Attendance.delete({id:attendance.id}, function(success){
                    $scope.event.attendances.splice($scope.event.attendances.indexOf(attendance), 1);
                    }, function (failure){
                        Flash.create('danger', 'an error occurred', 5000, {container:'flash-master'});
                })
            }
        }

        $scope.load = function () {
            $scope.eventTypes = Event.types({},
                function (success){
                    $scope.event.type = $scope.eventTypes[0]; 
                }
            );

            $scope.normalPrice = 0;
            $scope.concessionPrice = 0;
            $scope.freestylePrice = 0;
            $scope.prices = Price.query({},
            function (success) {
                angular.forEach(success, function (price){
                    if (price.name.toLowerCase() === 'normal'){
                        $scope.normalPrice = $scope.fee = price.amount;
                    } else if (price.name.toLowerCase()  === 'concession') {
                        $scope.concessionPrice = price.amount;
                    } else if (price.name.toLowerCase()  === 'freestyle') {
                        $scope.freestylePrice = price.amount;
                    }
                })
            }, function (failure){
            });

            Member.query({}, function (success) {
                $scope.members = angular.fromJson(angular.toJson(success));
                for (var i = 0; i < $scope.members.length; i++) {
                    if ($scope.members[i].membership_number === null) {
                        $scope.members[i].membership_number = 'no membership number';
                    }
                    if ($scope.members[i].email === null) {
                        $scope.members[i].email = 'no email';
                    }
                }
            });

            $scope.venues = Venue.query({},
                function(success){
                    if (!$routeParams.id){
                        $scope.event.venue = $scope.venues[0];
                    }
            });
            if ($routeParams.id) {
                $scope.event = Event.get({id:$routeParams.id},
                    function (success) {
                        $scope.event.date = new Date(success.date);
                        var name = success.type.name + ', ' + success.venue.name + ' - ' + $filter('date')(success.date);

                        if (success.type.id === 2) {
                            $scope.normalPrice = $scope.freestylePrice;
                        }
                        $rootScope.$emit('breadcrumbUpdate', {href:'', name:name});
                    }, function (response) {
                        Flash.create('danger', response.statusText, 5000, {container:'flash-master'})
                    }
                );
            } 
        }

        $scope.feeTotal = function () {
            var total = 0;
            if ($scope.event.attendances){
                for (var i = 0; i < $scope.event.attendances.length; i++) {
                    total += parseFloat($scope.event.attendances[i].fee);
                }
            }
            
            return total;
        }

        $scope.costTotal = function () {
            var total = 0;
            if ($scope.event.costs){
                for (var i = 0; i < $scope.event.costs.length; i++) {
                    total += parseFloat($scope.event.costs[i].amount);
                }
            }

            return total;
        }

        $scope.eventIncome = function () {
            return $scope.feeTotal() - $scope.costTotal();
        }

        $scope.save = function() {
            $scope.saveStatus = 'saving';
            if (isNaN($scope.event.id)){
                Event.save($scope.event,
                    function(success) {
                        $scope.success(success);
                        $scope.event.id = success.id;
                        $location.url('/events/edit/'+success.id);
                    },
                    function (failure){
                        $scope.failure(failure);  
                }).$promise.then(function(){
                    $scope.saveStatus = 'save';
                });
            } else {
                Event.update({id:$scope.event.id}, $scope.event,
                    function(success) {
                        $scope.success(success);
                    },
                    function (failure){
                        $scope.failure(failure);  
                })
            }
        }

        $scope.success = function (success) {
            $scope.saveStatus = 'save';
            Flash.create('success', 'Event saved successfully', 2000, {container:'flash-master'});
        }

        $scope.failure = function (failure){
            $scope.saveStatus = 'save';
            if (failure.status === 422) {
                var message = 'Validation failed: <ul>';
                Object.keys(failure.data).forEach(function(key,index) {
                    for (var i = 0; i < failure.data[key].length; i++) {
                        message += '<li>' + failure.data[key][i] + '</li>';
                    }
                });
                message += '</ul>';

                Flash.create('danger', message, 5000, {container:'flash-master'});
            }
        }

        $scope.addNewVenue = function () {
            var modalInstance = $uibModal.open({
                templateUrl: '/js/angular/venues/editModal.html',
                controller: 'VenueEditController',
                size: 'lg',
            });

            modalInstance.rendered.then(function(){
                $('.modal-content').css('max-height', 800);
            });

            modalInstance.result.then(function (venue) {
                $scope.event.venue = venue;
            });
        }

        $scope.addNewMember = function (role_id) {
            if (typeof role_id === 'undefined'){
                var role_id = 1;// customer
            }
            var modalInstance = $uibModal.open({
                templateUrl: '/js/angular/members/editModal.html',
                controller: 'MemberEditController',
                size: 'lg',
                resolve:
                {
                    role_id: function () {return role_id},
                    venue: function () {return $scope.event.venue},
                    memberId: function(){return null},
                    dateJoined: function(){return $scope.event.date}
                }
            });

            modalInstance.rendered.then(function(){
                $('.modal-content').css('max-height', 950);
            });

            modalInstance.result.then(function (member) {
                $scope.members.unshift(member);
                if (role_id === 2){
                    $scope.noStaffResults = false;
                    $scope.staff = member;
                } else {
                    $scope.noMemberResults = false;
                    $scope.member = member;
                }
            });
        }

        $scope.toggleTips = function () {
            $scope.showTips === false ? $scope.showTips = true: $scope.showTips = false;
        }
    }
    ]
);
