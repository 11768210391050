angular.module('venue')

.factory('Venue', ['$resource',
    function ($resource) {
        return $resource('api/venues/:id',
            [],
            {
                query: { method:'GET', isArray:true},
                update: { method:'PUT'}
            }
        )
    }
])