angular.module('report')
    .controller('ReportEventController', ['$scope', 'Report', 'Flash', '$location', '$routeParams', '$uibModal', '$rootScope', 'Template', 'Venue',
        function ($scope, Report, Flash, $location, $routeParams, $uibModal, $rootScope, Template, Venue) {
            $scope.title = '';
            $scope.reportType = '';
            $scope.chartData = {dates: [], series: []};
            $scope.venueColours = {
                1: '#2F7ED8',
                2: '#0D233A',
                3: '#8BBC21'
            }
            if ($routeParams.type) {
                switch ($routeParams.type) {
                    case 'income':
                        $scope.reportType = 'finance';
                        $scope.title = 'Event Income';
                        $scope.breadCrumb = {href: '', name: 'Event Income'};
                        break;
                    case 'attendance':
                        $scope.reportType = 'attendance';
                        $scope.title = 'Event Attendance';
                        $scope.breadCrumb = {href: '', name: 'Attendance'};
                        break;
                    default:
                }
            }
            $scope.venues = Venue.query();

            if (angular.isObject($scope.breadCrumb)) {
                $rootScope.$emit('breadcrumbUpdate', $scope.breadCrumb);
            }

            $scope.generate = function () {

                Report.query(
                    {
                        report: $scope.reportType,
                        period: $scope.selectedPeriod,
                    },
                    function (success) {
                        var data = angular.fromJson(angular.toJson(success));
                        var period;
                        if ($scope.reportType === 'attendance') {
                            for (var i = 0; i < data.length; i++) {
                                period = new Date(data[i].date);
                                $scope.chartData.dates.push(moment(period).format('d MMM YYYY'));
                                $scope.chartData.series.push(
                                    {
                                        y: data[i].customer_count,
                                        id: data[i].id,
                                        name: data[i].type.name + ', ' + data[i].venue.name,
                                        color: $scope.venueColours[data[i].venue.id],
                                    }
                                );
                            }
                        }
                        else if ($scope.reportType === 'finance') {
                            var fees = {name:'Customer Entries', data:[], stack:'fees'};
                            var costs = {name:'Costs', data:[], stack:'costs'};
                            var income = {name:'Income', data:[], stack:'income'};
                            for (var i = 0; i < data.length; i++) {
                                period = new Date(data[i].date);
                                $scope.chartData.dates.push(moment(period).format('d MMM YYYY'));
                                fees.data.push({y:parseFloat(data[i].fee_total), id:data[i].id, name: data[i].type.name + ', ' + data[i].venue.name});
                                costs.data.push({y:parseFloat(data[i].cost_total),  id:data[i].id,name: data[i].type.name + ', ' + data[i].venue.name});
                                income.data.push({y:parseFloat(data[i].income), id:data[i].id, name: data[i].type.name + ', ' + data[i].venue.name});
                            }
                            $scope.chartData.series.push(
                                fees, costs, income
                            );
                        }
                    },
                    function (failure) {
                        $scope.generated = true;
                        Flash.create('danger', 'An error occurred', 5000, {container: 'flash-master'})
                    }
                )
            }

            $scope.attendanceConfig =
                {
                    chart: {
                        type: 'column',
                    },
                    series: [{
                        name: 'customer count',
                        data: $scope.chartData.series,
                        title: 'Event Attendance'
                    }],
                    plotOptions: {
                        series: {
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: function () {
                                        var url = '/events/edit/'+this.options.id;
                                        $location.url(url);
                                        $scope.$apply();
                                    }
                                }
                            }
                        }
                    },
                    xAxis: {
                        categories: $scope.chartData.dates,
                    },
                    yAxis: {
                        title: 'Number'
                    },
                    title: {
                        text: 'Event Attendance last 6 months'
                    },
                    credits: {
                        enabled: false
                    },
                }
            $scope.incomeConfig =
                {
                    chart: {
                        type: 'column',
                    },
                    series: $scope.chartData.series,
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                        },
                        series: {
                            cursor: 'pointer',
                            point: {
                                events: {
                                    click: function () {
                                        var url = '/events/edit/'+this.options.id+'#finances';
                                        $location.url(url);
                                        $scope.$apply();
                                    }
                                }
                            }
                        }
                    },
                    xAxis: {
                        categories: $scope.chartData.dates,
                    },
                    tooltip: {
                        formatter: function() {
                            return this.key + ' ' + this.x + '<br/><b>' + this.series.name + '</b>: ' + this.y;
                        }
                    },
                    yAxis: {
                        title: 'Number',
                        stackLabels: {
                            enabled: true,
                            style: {
                                fontWeight: 'bold',
                                color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                            }
                        }
                    },
                    title: {
                        text: 'Event Income last 6 months'
                    },
                    credits: {
                        enabled: false
                    },
                }
        }
    ])