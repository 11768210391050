angular.module('price', [])

.config(["$routeProvider", "$locationProvider", function($routeProvider, $locationProvider) {
    $routeProvider
    .when('/prices', {
        templateUrl: '/js/angular/prices/index.html',
        controller: 'PriceListController',
        activeMenu: 'prices',
        breadcrumb: {href:'/prices', name:'Prices'},
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
    })
    $locationProvider.html5Mode(true);
    }
])

.factory('Price', ['$resource',
    function ($resource) {
        return $resource('api/prices/:id',
            [],
            {
                query: { method:'GET', isArray:true, cache:true, ignoreLoadingBar: true},
            }
        )
    }
])