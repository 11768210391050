angular.module('member', ['ngTagsInput'])

.config(["$routeProvider", "$locationProvider", function($routeProvider, $locationProvider) {
    $routeProvider
    .when('/members', {
        templateUrl: '/js/angular/members/index.html',
        controller: 'MemberListController',
        activeMenu: 'members',
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
        breadcrumb: {href:'/members/', name:'Members'}
    }) 
    .when('/members/new', {
        templateUrl: '/js/angular/members/edit.html',
        controller: 'MemberEditController',
        activeMenu: 'members',
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
        breadcrumb: {href:'/members/', name:'Members'},
        resolve: {
            $uibModalInstance: function () { return null; }, // <- workaround if you want to use $uibModalInstance in your controller.
            role_id: function(){return 1;},
            venue:function(){return null},
            memberId:function(){return null},
            dateJoined: function(){return null}
        }
    })
    .when('/members/edit/:id', {
        templateUrl: '/js/angular/members/edit.html',
        controller: 'MemberEditController',
        activeMenu: 'members',
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
        breadcrumb: {href:'/members/', name:'Members'},
        resolve: {
            $uibModalInstance: function () { return null; }, // <- workaround if you want to use $uibModalInstance in your controller.
            role_id: function(){return 1;},
            venue:function(){return null},
            memberId:function(){return null},
            dateJoined: function(){return null}
        }
    })      
    $locationProvider.html5Mode(true);
    }
])

.factory('Member', ['$resource',
    function ($resource) {
        return $resource('api/members/:id',
            [],
            {
                query: { method:'GET', isArray:true, cache:true, ignoreLoadingBar: true},
            }
        )
    }
])