angular.module('email')

.factory('Template', ['$resource',
    function ($resource) {
        return $resource('api/templates/:id',
            [],
            {
                query: { method:'GET', isArray:true},
                update: { method:'PUT'}
            }
        )
    }
])