angular.module('price')

.factory('Price', ['$resource',
    function ($resource) {
        return $resource('api/prices/:id',
            [],
            {
                query: { method:'GET', isArray:true},
                update: { method:'PUT'}
            }
        )
    }
])