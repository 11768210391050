angular.module('user')
.controller('UserListController', ['$scope', 'User',
    function($scope, User){
        $scope.numPerPage = 10;
        $scope.orderByField = 'name';
        $scope.reverseSort = false;
        $scope.users = User.query();
    }
    ]
);
