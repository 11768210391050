angular.module('report', ['highcharts-ng', 'uiGmapgoogle-maps'])

.config(["$routeProvider", "$locationProvider", function($routeProvider, $locationProvider) {
    $routeProvider
    .when('/reports', {
        templateUrl: '/js/angular/reports/index.html',
        controller: 'ReportListController',
        activeMenu: 'reports',
        breadcrumb: {href:'/reports/', name:'Reports'},
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
    }) 
    .when('/reports/members/location', {
        templateUrl: '/js/angular/reports/location.html',
        controller: 'ReportLocationController',
        activeMenu: 'reports',
        breadcrumb:  {href:'/reports/', name:'Reports'},
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
        resolve: {
            $uibModalInstance: function () { return null; }, // <- workaround if you want to use $uibModalInstance in your controller.
        }
    })
    .when('/reports/members/:type', {
        templateUrl: '/js/angular/reports/members.html',
        controller: 'ReportMemberController',
        activeMenu: 'reports',
        breadcrumb:  {href:'/reports/', name:'Reports'},
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
        resolve: {
            $uibModalInstance: function () { return null; }, // <- workaround if you want to use $uibModalInstance in your controller.
        }
    })
        .when('/reports/events/:type', {
            templateUrl: '/js/angular/reports/events.html',
            controller: 'ReportEventController',
            activeMenu: 'reports',
            breadcrumb:  {href:'/reports/', name:'Reports'},
            reloadOnSearch: false, // stop reload when switching tabs and updating location.path
            resolve: {
                $uibModalInstance: function () { return null; }, // <- workaround if you want to use $uibModalInstance in your controller.
            }
        })
         
    $locationProvider.html5Mode(true);
    }
])

.config(['uiGmapGoogleMapApiProvider',
    function(uiGmapGoogleMapApiProvider) {
        uiGmapGoogleMapApiProvider.configure({
            key: 'AIzaSyDKXbw4XeSYpLnEuZZoFH08Im6I5zVOcsQ',
            //v: '3.24', //defaults to latest 3.X anyhow
            //libraries: 'weather,geometry,visualization'
        });
}])

.factory('Report', ['$resource',
    function ($resource) {
        return $resource('api/reports/:id',
            [],
            {
                query: { method:'GET', isArray:true, cache:true, ignoreLoadingBar: true},
            }
        )
    }
])