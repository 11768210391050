angular.module('email')
.controller('EmailEditController', ['$scope', 'Email', 'Flash', '$location', '$routeParams',
    function ($scope, Email, Flash, $location, $routeParams) {
        $scope.formName = 'emailForm';
        $scope.saveStatus = 'save';
        $scope.showConfirmDuplicate = false;
        $scope.stateClass = function (elementName){
            if ($scope[$scope.formName][elementName].$dirty){
                return $scope.state(elementName) === 'valid' ? 'has-success' : 'has-error';
            }
            return '';
        }

        $scope.state = function (elementName){
            return $scope[$scope.formName][elementName].$valid ? 'valid' : 'invalid';
        }

        $scope.stateIcon = function(elementName) {
            if ($scope[$scope.formName][elementName].$dirty){
                return $scope.state(elementName) === 'valid' ? 'glyphicon-ok' : 'glyphicon-remove';
            }
            return '';
        }        
        $scope.load = function () {
            if ($routeParams.id) {
                $scope.email = Email.get({id:$routeParams.id},
                    function (success) {
                    }, function (response) {
                        Flash.create('danger', response.statusText, 5000, {container:'flash-master'})
                    }
                );
            } 
        }

        $scope.save = function() {
            $scope.saveStatus = 'saving';
            $scope.email.postcode = $scope.email.postcode.toUpperCase();
            if (isNaN($scope.email.id)){
                Email.save($scope.email,
                    function(success) {
                        $scope.success(success);
                        $scope.email.id = success.id;
                        $location.url('/emails/edit/'+success.id);
                    },
                    function (failure){
                        $scope.failure(failure);  
                }).$promise.then(function(){
                    $scope.saveStatus = 'save';
                });
            } else {
                Email.update({id:$scope.email.id}, $scope.email,
                    function(success) {
                        $scope.success(success);
                    },
                    function (failure){
                        $scope.failure(failure);  
                }).$promise.then(function(){
                    $scope.saveStatus = 'save';
                }); 
            }
        }

        $scope.success = function (success) {
            Flash.create('success', 'Email saved successfully', 2000, {container:'flash-master'});
            $scope.showConfirmDuplicate = false;
        }

        $scope.failure = function (failure){
            if (failure.status === 409){
                var existingEmail = failure.data.duplicate;
                var message = 'A email with this name already exists, please confirm to add another, the existing email is:<ul>';
                message += '<li>Name: '+existingEmail.name + '</li>';
                message += '<li>Postcode: '+existingEmail.postcode+'</li>';
                message += '</ul>';
                
                Flash.create('danger', message, 10000, {container:'flash-master'});
                $scope.showConfirmDuplicate = true;
            } else if (failure.status === 422) {
                var message = 'Validation failed: <ul>';
                Object.keys(failure.data).forEach(function(key,index) {
                    for (var i = 0; i < failure.data[key].length; i++) {
                        message += '<li>' + failure.data[key][i] + '</li>';
                    }
                });
                message += '</ul>';

                Flash.create('danger', message, {container:'flash-master'});
            }
        }
    }
])