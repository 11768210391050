angular.module('event')
.controller('EventListController', ['$scope', 'Event', 'Flash',
    function($scope, Event, Flash){
        $scope.events = Event.query();
        $scope.allCheckboxesChecked = false;
        $scope.numPerPage = 10;
        $scope.orderByField = 'date';
        $scope.reverseSort = true;
        $scope.checkAllToggle = function () {
            $('input:checkbox').prop('checked', $scope.allCheckboxesChecked);
        }
        
        $scope.deleteEvent = function (event) {
            if (confirm("Are you sure you want to delete this event?")){
                Event.delete({id:event.id}, function(success){
                        Flash.create('success', 'Event deleted', 5000, {container:'flash-master'});
                        $scope.events.splice($scope.events.indexOf(event), 1);
                    }, function (failure){
                        Flash.create('danger', 'an error occurred', 5000, {container:'flash-master'});
                })
            }
        }
    }
    ]
);
