angular.module('email', ['ui.tinymce'])

.config(["$routeProvider", "$locationProvider", function($routeProvider, $locationProvider) {
    $routeProvider
    .when('/emails', {
        templateUrl: '/js/angular/emails/index.html',
        controller: 'EmailListController',
        activeMenu: 'emails',
        breadcrumb: {href:'/emails', name:'Emails'},
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
    }) 
    .when('/emails/compose', {
        templateUrl: '/js/angular/emails/compose.html',
        controller: 'EmailComposeController',
        activeMenu: 'emails',
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
    })
    .when('/emails/list', {
        templateUrl: '/js/angular/emails/index.html',
        controller: 'EmailListController',
        activeMenu: 'emails',
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
    }) 
    .when('/emails/new', {
        templateUrl: '/js/angular/emails/edit.html',
        controller: 'EmailEditController',
        activeMenu: 'emails',
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
    })
    .when('/emails/edit/:id', {
        templateUrl: '/js/angular/emails/edit.html',
        controller: 'EmailEditController',
        activeMenu: 'emails',
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
    })  
    .when('/emails/view/:id', {
        templateUrl: '/js/angular/emails/view.html',
        controller: 'EmailViewController',
        activeMenu: 'emails',
        breadcrumb: {href:'/emails', name:'Emails'},
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
    })   
    .when('/emails/templates', {
        templateUrl: '/js/angular/emails/templates/index.html',
        controller: 'TemplateListController',
        activeMenu: 'emails',
        breadcrumb: [
            {href:'/emails', name:'Emails'},
            {href:'/emails/templates', name:'Templates'}
        ],
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
    }) 
    .when('/emails/templates/new', {
        templateUrl: '/js/angular/emails/templates/edit.html',
        controller: 'TemplateEditController',
        activeMenu: 'emails',
        breadcrumb: [
            {href:'/emails', name:'Emails'},
            {href:'/emails/templates', name:'Templates'}
        ],
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
    })
    .when('/emails/templates/edit/:id', {
        templateUrl: '/js/angular/emails/templates/edit.html',
        controller: 'TemplateEditController',
        activeMenu: 'emails',
        breadcrumb: [
            {href:'/emails', name:'Emails'},
            {href:'/emails/templates', name:'Templates'}
        ],
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
    })
    $locationProvider.html5Mode(true);
    }
])

