angular.module('report')
.controller('ReportLocationController', ['$scope', 'Report', 'uiGmapIsReady', 'moment',
    function($scope, Report, uiGmapIsReady, moment){
        $scope.map =  {
            center: {latitude: 51.503491, longitude: -0.142019},
            zoom: 13,
        };
        $scope.start_date = new Date(new Date().setFullYear(new Date().getFullYear() -1));
        $scope.end_date = new Date();

        $scope.load= function() {
            $scope.markers = [];
            Report.query(
                {
                    report:'location',
                    dateJoinedStart: $scope.start_date,
                    dateJoinedEnd:$scope.end_date
                },
                function(success){
                    uiGmapIsReady.promise(1).then(function(instances) {
                        $scope.members = angular.fromJson(angular.toJson(success));
                        instances.forEach(function(inst) {
                            var map = inst.map;
                            var bounds = new google.maps.LatLngBounds();

                            angular.forEach($scope.members, function (member, key){
                                $scope.markers.push( {
                                    id:member.id,
                                    position: {latitude: member.latitude, longitude: member.longitude},
                                    member: member,
                                });
                                bounds.extend(new google.maps.LatLng(member.latitude, member.longitude));
                            });
                            
                            if ($scope.markers.length === 0) {// centre on UK
                                map.setCenter({lat: 54.521081, lng: -4.152832});
                                map.setZoom(5);
                            } else if ($scope.markers.length === 1){
                                var latLng = {
                                    lat:parseFloat($scope.markers[0].position.latitude),
                                    lng:parseFloat($scope.markers[0].position.longitude)
                                };
                                map.setCenter(latLng);
                                map.setZoom(13);
                            } else {
                                map.fitBounds(bounds);
                            }
                        })
                    })
            });
        }
}]);