angular.module('member')

.controller('MemberEmailController', ['$scope', '$uibModalInstance', 'recipients', 'Email', 'Flash', 'template', 'Template',
    function ($scope, $uibModalInstance, recipients, Email, Flash, template, Template) {
        $scope.templates = Template.query();
        $scope.selectedTemplate = template;
        $scope.updateEmailFromTemplate = function (template) {
            if (template.subject) {
                $scope.email.subject = template.subject;
            }

            if (template.html) {
                $scope.email.message = template.html;
            } 
        }
        $scope.email = {
            subject: '',
            message:'', 
            recipients:recipients}

        $scope.updateEmailFromTemplate(template);
        // tags input requires an id property, where an email with no user id, use fake one
        $scope.fakeRecipientId = 0;

        $scope.tinymceOptions = {
            height: 250
        }

        $scope.changeTemplate = function () {
            $scope.updateEmailFromTemplate($scope.selectedTemplate)
        }

        $scope.sendTest = function () {
            Email.save({test:true}, $scope.email,
                function (success) {
                    Flash.create('success', 'Test email sent successfully', 5000, {container:'flash-master'})
                }, function (response) {
                    Flash.create('danger', response.statusText, 5000, {container:'flash-master'})
                }
            )
        }

        $scope.send = function () {
            if (confirm("Are you sure you wish to send this email") === true){
                Email.save({}, $scope.email,
                    function (success) {
                        Flash.create('success', 'Email sent', 5000, {container:'flash-master'})
                        $uibModalInstance.close();

                    }, function (response) {
                        Flash.create('danger', response.statusText, 5000, {container:'flash-master'})
                    }
                )
            }
        }

        $scope.cancel = function (){
            $uibModalInstance.dismiss();
        }

        $scope.addingTag = function ($tag) {
            if (!$tag.id) {
                $tag.id = $scope.fakeRecipientId--;
            }
        }
    }
])