angular.module('user')

.factory('User', ['$resource',
    function ($resource) {
        return $resource('api/users/:id',
            [],
            {
                query: { method:'GET', isArray:true},
                update: { method:'PUT'}
            }
        )
    }
])