angular.module('user')
.controller('UserEditController', ['$scope', 'User', 'Flash', '$location', '$routeParams', '$uibModalInstance', '$rootScope', '$sce',
    function ($scope, User, Flash, $location, $routeParams, $uibModalInstance, $rootScope, $sce) {
        $scope.formName = 'userForm';
        $scope.saveStatus = 'save';
        $scope.showConfirmDuplicate = false;
        $scope.user = {role:'user'};
        $scope.roles = ['admin', 'user'];
        $scope.stateClass = function (elementName){
            if ($scope[$scope.formName][elementName].$dirty){
                return $scope.state(elementName) === 'valid' ? 'has-success' : 'has-error';
            }
            return '';
        }

        $scope.userTip = $sce.trustAsHtml("for differences between user types see the help section (from drop down menu on top right)");

        $scope.state = function (elementName){
            return $scope[$scope.formName][elementName].$valid ? 'valid' : 'invalid';
        }

        $scope.stateIcon = function(elementName) {
            if ($scope[$scope.formName][elementName].$dirty){
                return $scope.state(elementName) === 'valid' ? 'glyphicon-ok' : 'glyphicon-remove';
            }
            return '';
        }
        
        $scope.load = function () {
            if ($uibModalInstance === null && $routeParams.id) {
                $scope.user = User.get({id:$routeParams.id},
                    function (success) {
                        $rootScope.$emit('breadcrumbUpdate', {href:'', name:success.name});
                    }, function (response) {
                        Flash.create('danger', response.statusText, 5000, {container:'flash-master'})
                    }
                );
            } 
        }

        $scope.save = function() {
            $scope.saveStatus = 'saving';
            if ($scope.user.postcode ){
                $scope.user.postcode = $scope.user.postcode.toUpperCase();
            }
            if (isNaN($scope.user.id)){
                User.save($scope.user,
                    function(success) {
                        $scope.success(success);
                        $scope.user.id = success.id;
                    },
                    function (failure){
                        $scope.failure(failure);  
                });
            } else {
                User.update({id:$scope.user.id}, $scope.user,
                    function(success) {
                        $scope.success(success);
                    },
                    function (failure){
                        $scope.failure(failure);  
                });
            }
        }

        $scope.success = function (success) {
            Flash.create('success', 'User saved successfully', 5000, {container:'flash-master'});
            $scope.saveStatus = 'save';
 
            if ($uibModalInstance !== null) {
                $uibModalInstance.close(success);
            } else {
                $location.url('/users/edit/'+success.id);
            }
        }

        $scope.failure = function (failure){
            $scope.saveStatus = 'save';
            if (failure.status === 409){
                var existingUser = failure.data.duplicate;
                var message = 'A user with this name already exists, please confirm to add another, the existing user is:<ul>';
                message += '<li>Name: '+existingUser.name + '</li>';
                message += '<li>Postcode: '+existingUser.postcode+'</li>';
                message += '</ul>';

                Flash.create('danger', message, 10000, {container:'flash-master'});
                $scope.showConfirmDuplicate = true;
            } else if (failure.status === 422) {
                var message = 'Validation failed: <ul>';
                Object.keys(failure.data).forEach(function(key,index) {
                    for (var i = 0; i < failure.data[key].length; i++) {
                        message += '<li>' + failure.data[key][i] + '</li>';
                    }
                });
                message += '</ul>';

                Flash.create('danger', message, 5000, {container:'flash-master'});
            } else {
                Flash.create('danger', 'An Error Occurred', 5000, {container:'flash-master'});
            }
        }

        $scope.cancel = function () {
            if ($uibModalInstance === null) {
                window.history.back();
            } else {
                $uibModalInstance.dismiss('cancel');
            }
        }
    }
])