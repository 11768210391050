angular.module('member')

.factory('Member', ['$resource', '$cacheFactory',
    function ($resource, $cacheFactory) {
        var memberCache = $cacheFactory('members');
        var updateCache = function (result) {
            var updatedMember = angular.fromJson(result);
            var cacheKey = 'api/members';
            var memberCache = $cacheFactory.get('members').get(cacheKey);
            if (typeof memberCache === 'undefined') {
                return updatedMember;
            }

            var members = angular.fromJson(memberCache[1]);
            angular.forEach(members, function (member, key) {
                if (member.id === updatedMember.id) {
                    member = updatedMember;
                }
            });
            memberCache[1] = angular.toJson(members);
            $cacheFactory.get('members').put(cacheKey, memberCache);

            return updatedMember;
        }
        return $resource('api/members/:id',
            [],
            {
                query: { method:'GET', isArray:true, cache:memberCache},
                update: { method:'PUT'},
                sources: { method:'GET', isArray:true, url:'/api/sources'},
                merge: { method:'PUT', url:'/api/members/merge'},
                restore: {method:'PUT', transformResponse: function (result) {
                        return updateCache(result);
                    }, url:'/api/members/restore'}
            }
        )
    }
])