angular.module('venue', [])

.config(["$routeProvider", "$locationProvider", function($routeProvider, $locationProvider) {
    $routeProvider
    .when('/venues', {
        templateUrl: '/js/angular/venues/index.html',
        controller: 'VenueListController',
        activeMenu: 'venues',
        breadcrumb: {href:'/venues', name:'Venues'},
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
    }) 
    .when('/venues/new', {
        templateUrl: '/js/angular/venues/edit.html',
        controller: 'VenueEditController',
        activeMenu: 'venues',
        breadcrumb: {href:'/venues', name:'Venues'},
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
        resolve: {
            $uibModalInstance: function () { return null; }, // <- workaround if you want to use $uibModalInstance in your controller.
        }
    })
    .when('/venues/edit/:id', {
        templateUrl: '/js/angular/venues/edit.html',
        controller: 'VenueEditController',
        activeMenu: 'venues',
        breadcrumb: {href:'/venues', name:'Venues'},
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
        resolve: {
            $uibModalInstance: function () { return null; }, // <- workaround if you want to use $uibModalInstance in your controller.
        }
    })      
    $locationProvider.html5Mode(true);
    }
])

.factory('Venue', ['$resource',
    function ($resource) {
        return $resource('api/venues/:id',
            [],
            {
                query: { method:'GET', isArray:true, cache:true, ignoreLoadingBar: true},
            }
        )
    }
])