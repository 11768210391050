angular.module('event', [])

.config(["$routeProvider", "$locationProvider", function($routeProvider, $locationProvider) {
    $routeProvider
    .when('/events', {
        templateUrl: '/js/angular/events/index.html',
        controller: 'EventListController',
        activeMenu: 'events',
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
        breadcrumb: {href:'/events/', name:'Events'}
    }) 
    .when('/events/new', {
        templateUrl: '/js/angular/events/new.html',
        controller: 'EventEditController',
        activeMenu: 'events',
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
        breadcrumb: {href:'/events/', name:'Events'}
    })
    .when('/events/edit/:id', {
        templateUrl: '/js/angular/events/edit.html',
        controller: 'EventEditController',
        activeMenu: 'events',
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
        breadcrumb: {href:'/events/', name:'Events'}
    })      
    $locationProvider.html5Mode(true);
    }
])
