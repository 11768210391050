angular.module('user', [])

.config(["$routeProvider", "$locationProvider", function($routeProvider, $locationProvider) {
    $routeProvider
    .when('/users', {
        templateUrl: '/js/angular/users/index.html',
        controller: 'UserListController',
        activeMenu: 'users',
        breadcrumb: {href:'/users', name:'Users'},
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
    }) 
    .when('/users/new', {
        templateUrl: '/js/angular/users/edit.html',
        controller: 'UserEditController',
        activeMenu: 'users',
        breadcrumb: {href:'/users', name:'Users'},
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
        resolve: {
            $uibModalInstance: function () { return null; }, // <- workaround if you want to use $uibModalInstance in your controller.
        }
    })
    .when('/users/edit/:id', {
        templateUrl: '/js/angular/users/edit.html',
        controller: 'UserEditController',
        activeMenu: 'users',
        breadcrumb: {href:'/users', name:'Users'},
        reloadOnSearch: false, // stop reload when switching tabs and updating location.path
        resolve: {
            $uibModalInstance: function () { return null; }, // <- workaround if you want to use $uibModalInstance in your controller.
        }
    })      
    $locationProvider.html5Mode(true);
    }
])

.factory('User', ['$resource',
    function ($resource) {
        return $resource('api/users/:id',
            [],
            {
                query: { method:'GET', isArray:true, cache:true, ignoreLoadingBar: true},
            }
        )
    }
])