angular.module('email')
.controller('EmailListController', ['$scope', 'Email', '$sce',
    function($scope, Email, $sce){
        $scope.numPerPage = 10;
        $scope.orderByField = 'name';
        $scope.reverseSort = false;
        $scope.emails = Email.query();
        $scope.messagePreviewLength = 100;
        
        $scope.emailMessage = function (email) {
            if (email.message.length > $scope.messagePreviewLength) {
                return $sce.trustAsHtml(email.message.substring(0, $scope.messagePreviewLength) + '.....');
            }
            return 
        }
    }
    ]
);
